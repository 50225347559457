<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-card>
          <v-card-actions>
            <v-icon @click="close">mdi-arrow-left-circle</v-icon>

            <v-spacer></v-spacer>

            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="pr-0 mr-3">
        <v-card>
          <v-card-text>
            <v-col class="d-flex" cols="3">
              <v-radio-group v-model="vm.typeOfFeedback" label="Type of Feedback">
                <v-radio label="Verbal" value="Verbal"></v-radio>
                <v-radio label="Written" value="Written"></v-radio>
                <v-radio label="Other" value="Other"></v-radio>
              </v-radio-group>
            </v-col>
           
            <v-col cols="12">
              <date-time-picker
                :datetime="vm.date"
                v-model="vm.date"
                label="date"
                prepend-icon="mdi-calendar"
              >
              </date-time-picker>
            </v-col>

            <v-col cols="12">
              <v-select
                :items="personTypes"
                label="Person Providing Feedback"
                v-model="vm.personProvidingFeedback"
                data-vv-as="personProvidingFeedback"
                name="personProvidingFeedback"
                v-validate="'required'"
                :error-messages="errors.collect('personProvidingFeedback')"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.personName"
                label="Person Name"
                required
                data-vv-as="personName"
                name="personName"
                v-validate="'required'"
                :error-messages="errors.collect('personName')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="vm.Feedback"
                label="Please enter the feedback being provided"
                data-vv-as="feedback"
                name="feedback"
                v-validate="'required'"
                :error-messages="errors.collect('feedback')"
                filled
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="vm.respondersName"
                label="Responders Name"
                data-vv-as="respondersName"
                name="respondersName"
                v-validate="'required'"
                :error-messages="errors.collect('respondersName')"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="vm.responseProvidedToFeedback"
                label="Respond provided to the feedback"
                data-vv-as="responseProvidedToFeedback"
                name="responseProvidedToFeedback"
                v-validate="'required'"
                :error-messages="errors.collect('responseProvidedToFeedback')"
                filled
              ></v-textarea>
            </v-col>
            <v-col>
              <v-radio-group v-model="vm.typeOfResponse" label="Type of Response">
                <v-radio
                  label="Provided Suggestions"
                  value="Provided-Suggestions"
                ></v-radio>
                <v-radio label="Action in place" value="Action-In-Place"></v-radio>
                <v-radio label="Other" value="Other"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <date-time-picker
                :datetime="vm.dateDate"
                v-model="vm.dateDate"
                label="Date Closed"
                prepend-icon="mdi-calendar"
              ></date-time-picker>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close">Cancel</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as feedbackService from "../../services/feedbackService";
//import * as nodemailerService from "../../services/nodemailerService";
import * as firebaseEmailService from "../../services/firebaseEmailService";
import DateTimePicker from "../../components/core/DateTimePicker";
import * as notificationService from "../../services/notificationService";

 
Vue.use(VeeValidate);

export default {
  name: "feedback-form",
  components: {
    DateTimePicker,
  },
  data: () => ({
    vm: { date: new Date() },
    dateMenu: false,
    emails: [],
    form: {},
    personTypes: ["Community Member", "Participant", "Participant Family", "Other"],
  }),
  watch: {
    feedback(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  props: {
    feedback: Object,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentFeedback() {
      return this.$store.getters.currentFeedback;
    },
  },
  methods: {
   async init() {
      if (this.currentFeedback !== undefined && this.currentFeedback !== null) {
        console.log(this.currentFeedback);
        this.vm = this.currentFeedback;
      }
      await notificationService.getFeedbackById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.emails = temp.Emails;
        console.log(this.emails);
      });
    

    },
    /*
    updateDateDatetime: function(datetime) {
      this.vm.date = datetime;
    },*/
    close() {
      this.$router.push("/feedback/general-feedback");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
    validateBeforeSubmit() {
      let me = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          // me.vm.dateDate = new Date(me.vm.date);
          console.log(me.vm.dateDate);
          console.log(me.vm.date);
          feedbackService.save(me.currentOrganisation.id, me.vm).then((ref) => {
            if (ref != undefined) {
              me.vm.id = ref.id;
            }
             console.log(me.user.email);   

             let fromID=me.user.email;
             let fromName=me.user.displayName;
             let toID=this.emails;
             console.log(fromID);
             let subject="Omar notifications - new feedback";
             let text=me.vm.personName +" provided feedback on service";
            
             let sendEmail="Do you want to send an Email?";
             
             if (confirm(sendEmail) == true) {
                   firebaseEmailService.sendFeedback(fromID, fromName, toID, subject, text);
                 
              } else {
                  console.log("not sent");
              }
            me.$store.dispatch("setToast", {
              message: "Feedback saved",
              color: "primary",
            });

            me.close();
          });
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4",
          });
        }
      });
    },
  
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
   
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>
